import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { OnboardingItemComponent } from './onboarding-item/onboarding-item.component';
import { OnboardingConfig, OnboardingStep, WaitFor } from './onboarding.model';
import { OnboardingService } from './onboarding.service';

const onboardingHighglightClassName = 'onboarding-highlighted';

@Directive({
  selector: '[libOnboardingItem]',
})
export class OnboardingItemDirective implements OnInit, OnDestroy {
  private onboarder = inject(OnboardingService);
  private elementRef = inject(ElementRef<any>);
  private viewContainer = inject(ViewContainerRef);
  private injector = inject(Injector);

  @Input() libOnboardingItem!: string;
  @Input() collector?: () => Record<string, any>;

  flow?: OnboardingConfig;
  stepConfig?: OnboardingStep;

  subscriptions: Subscription[] = [];

  _isHighlighted = false;
  get isHighlighted() {
    return this._isHighlighted;
  }
  set isHighlighted(flag: boolean) {
    this.contentElement.classList.toggle(onboardingHighglightClassName, flag);
    if (flag && !this._isHighlighted) {
      this.compRef = this.viewContainer.createComponent(OnboardingItemComponent, { injector: this.injector });
      this.compRef.instance.highlightElement = this.elementRef;
      this.compRef.instance.stepConfig = this.stepConfig;
      // This is probably not the Angular way of attaching a new component to the DOM tree
      // but by using ApplicationRef I could only attach it to the app-root component and not the document body.
      // Not sure if that is a bad idea though, it just wouldn't detach once next step was due.
      document.body.appendChild(this.compRef.location.nativeElement);
      this.highlighted.emit();
      this.compRef.changeDetectorRef.markForCheck();
    } else if (!flag && this._isHighlighted) {
      this.compRef?.destroy();
      this.viewContainer.clear();
    }
    if (this.stepConfig) this.stepConfig._active = flag;
    this._isHighlighted = flag;
  }
  compRef?: ComponentRef<any>;

  @Output() highlighted = new EventEmitter();
  @Output() done = new EventEmitter();

  clickHandler?: (evt: MouseEvent) => void;

  get contentElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.onboarder.addItem(this);
    this.subscriptions.push(
      this.onboarder.activeFlow$
        .pipe(
          // Only react to this flow if it contains the current element
          filter(flow => flow?.flowSequence?.findIndex(step => step.elementID === this.libOnboardingItem) > -1),
        )
        .subscribe(flow => {
          this.flow = flow;
          if (flow.flowPlayedMs != null && flow.flowType === 'collect') {
            // This flow has played before. The step is therefore reduced to a tooltip
            this.stepConfig = flow?.flowSequence?.find(step => step.elementID === this.libOnboardingItem);
          }
        }),
    );
  }

  ngOnDestroy() {
    this.deactivate();
    this.onboarder.removeItem(this);
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  activate(step: OnboardingStep) {
    this.stepConfig = step;
    this.isHighlighted = this.stepConfig != null;

    // Analyze step and determine what triggers the step to complete
    if (step.waitFor === WaitFor.CLICK) {
      this.clickHandler = (evt: MouseEvent | PointerEvent) => {
        if (this.elementRef.nativeElement.contains(evt.target)) {
          // Only deactivate if the element clicked is actually contained within the element
          // bound to this directive
          if (this.onboarder.activeFlow$.getValue().flowType === 'collect' && this.collector != null) {
            step.collectedValue = this.collector();
          }
          this.deactivate();
        }
      };
      this.contentElement?.addEventListener('click', this.clickHandler);
    }
  }

  deactivate(emit = true) {
    if (this.clickHandler != null) {
      this.contentElement?.removeEventListener('click', this.clickHandler);
      this.clickHandler = undefined;
    }
    if (this.flow?.flowType === 'collect' && this.stepConfig != null && this.collector != null) {
      // Should collect state even if the flow is aborted
      this.stepConfig.collectedValue = this.collector();
    }
    if (this.isHighlighted) {
      this.isHighlighted = false;
      if (emit) this.done.emit();
    }
  }

  @HostListener('click')
  onClick() {
    if (this.flow?.flowType === 'collect' && this.stepConfig != null && this.collector != null && !this.isHighlighted) {
      // Collect the value even if this step is not part of an active flow
      this.stepConfig.collectedValue = this.collector();
      this.onboarder.markAsPlayed(this.flow);
    }
  }
}
