import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { RequestCache } from '@logic-suite/shared/cache';
import { LoaderService } from '@logic-suite/shared/components/loader';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  catchError,
  debounceTime,
  delay,
  filter,
  firstValueFrom,
  map,
  of,
  retryWhen,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { OnboardingItemDirective } from './onboarding-item.directive';
import { OnboardingConfig, OnboardingList, OnboardingStep } from './onboarding.model';

export enum TourState {
  INACTIVE,
  ACTIVE,
  NO_MORE,
}

@Injectable({
  providedIn: 'root',
})
export class OnboardingService implements OnDestroy {
  private router = inject(Router);
  private loader = inject(LoaderService);
  private snack = inject(MatSnackBar);
  private http = inject(HttpClient);
  private cache = inject(RequestCache);

  private itemRepository$ = new BehaviorSubject<OnboardingItemDirective[]>([]);
  activeFlow$ = new BehaviorSubject<OnboardingConfig>({} as OnboardingConfig);
  activeFlow = toSignal(this.activeFlow$);
  private activeStep$ = new BehaviorSubject<OnboardingStep>({} as OnboardingStep);
  tourState$ = new BehaviorSubject<TourState>(TourState.INACTIVE);
  currentStepIndex = 0;
  isPlaying = computed(() => this.activeFlow()?._isPlaying);

  subscriptions: Subscription[] = [];

  constructor() {
    this.subscriptions.push(this.activeStep$.subscribe(step => this.playActiveStep(step)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * A new item has been added to the DOM. Register it here.
   * This is only called from the OnbaordingItemDirective's OnInit hook.
   *
   * @param item
   */
  addItem(item: OnboardingItemDirective) {
    const values = this.itemRepository$.getValue();
    values.push(item);
    this.itemRepository$.next(values);
  }

  /**
   * An item has been removed from the DOM. Unregister it here.
   * This is only called from the OnbaordingItemDirective's OnDestroy hook.
   *
   * @param item
   */
  removeItem(item: OnboardingItemDirective) {
    const values = this.itemRepository$.getValue();
    const index = values.findIndex(i => i === item);
    if (index > -1) {
      values.splice(index, 1);
      this.itemRepository$.next(values);
    }
  }

  /**
   * Find the item with the given elementID
   * This is only called from within the play() method.
   *
   * @param elementID
   * @returns
   */
  private findItem(elementID: string): OnboardingItemDirective | undefined {
    return this.itemRepository$.getValue().find(item => item.libOnboardingItem === elementID);
  }

  /**
   * Initialize the onboarding engine. This will wait for an active flow
   * to be selected, and then play the first step in the flow. Each step
   * is responsible for handing over to the next step.
   */
  async play() {
    this.tourState$.next(TourState.ACTIVE);
    await firstValueFrom(
      this.activeFlow$.pipe(
        map(flow => {
          if (!flow) return {} as OnboardingConfig;
          flow._isPlaying = true;
          return flow;
        }),
        switchMap(flow => {
          if (flow?.flowSequence?.length > 0) {
            // A new flow is set. Start the tour.
            this.currentStepIndex = 0;
            const firstStep = flow.flowSequence[this.currentStepIndex];
            this.activeStep$.next(firstStep);
            return this.activeStep$;
          } else {
            this.tourState$.next(TourState.NO_MORE);
          }
          return of({} as OnboardingStep);
        }),
      ),
    );
  }

  endTour() {
    const currentFlow = this.activeFlow$.getValue();
    if (currentFlow?.flowID == null) return;

    const currentSequence = currentFlow.flowSequence;
    if (
      currentSequence &&
      currentSequence.length > 0 &&
      this.currentStepIndex < currentSequence.length &&
      currentSequence[this.currentStepIndex]._active
    ) {
      this.findItem(currentSequence[this.currentStepIndex].elementID)?.deactivate(false);
    }

    // Remove flow
    this.activeFlow$.next({} as OnboardingConfig);
    this.markAsPlayed(currentFlow);

    // Set state so that next flow can be loaded
    if (this.tourState$.getValue() !== TourState.INACTIVE) {
      this.tourState$.next(TourState.INACTIVE);
    }
  }

  /**
   * Initialize a given onboarding step.
   *
   * @param step
   */
  private async playActiveStep(
    step: OnboardingStep = this.activeFlow$.getValue()?.flowSequence[this.currentStepIndex],
    retries = 0,
  ) {
    if (step?.name) {
      // A step has been set.
      if (step.route) {
        // This step requires a navigation to be performed. Do it.
        this.router.navigate([step.route]);
        const route = await firstValueFrom(this.router.events.pipe(filter(event => event instanceof NavigationEnd)));
        if (route instanceof NavigationEnd && route.urlAfterRedirects !== step.route && retries < 3) {
          // Route has been navigated, but the url is wrong. Retry up to 4 times.
          const res = (await this.playActiveStep(step, retries + 1)) as boolean;
          return res;
        }
      }
      // Wait for all items to be visible. This will happen 1000ms after the last item has been
      // added to the item repository. Then find the item with the given elementID
      let abort = false;
      try {
        const item = await firstValueFrom(
          this.loader.isLoading$().pipe(
            debounceTime(100),
            filter(() => this.loader.isLoading() == false),
            switchMap(() => this.itemRepository$.pipe(debounceTime(100))),
            map(items => {
              const item = items.find(i => i.libOnboardingItem === step.elementID);
              const sequence = this.activeFlow$.getValue().flowSequence;
              if (!item) {
                // Item not found. Check if we have a repeatUntilElementID in our step and this element has become visible
                if (step.repeatUntilElementID != null && this.findItem(step.repeatUntilElementID) != null) {
                  // Abort this step and start the next one
                  abort = true;
                  if (sequence[this.currentStepIndex] === step) this.nextStep();
                } else {
                  // Find the first matching flow step in currently active view,
                  // which occurs AFTER the currentStepIndex (IMPORTANT! We do not want to start all over)
                  // This is a fallback in case data made user skip a step
                  const itemsInCurrentView = items.map(i => i.libOnboardingItem);
                  const itemsInFlow = sequence.map(s => s.elementID);
                  const idx = itemsInFlow.findIndex((elm: string, i: number) =>
                    i >= this.currentStepIndex ? itemsInCurrentView.includes(elm) : false,
                  );
                  if (idx > -1) {
                    this.currentStepIndex = idx;
                    abort = true;
                    this.playActiveStep(sequence[idx]);
                  } else {
                    throw new Error(`Could not find item with elementID ${step.elementID}`);
                  }
                }
              }
              return item;
            }),
            retryWhen(errors => errors.pipe(delay(500), take(6))),
          ),
        );
        if (item) {
          // Highlight the item and wait for the given waitFor event
          item.activate(step);
          // Handover to next step
          firstValueFrom(item.done).then(() => this.nextStep());
          // ... and we're done!
          return true;
        }
      } catch (ex) {
        if (!abort) {
          // If we reach this, the item was not found. This is a configuration error.
          // Report and die!
          this.snack.open(`No item found for elementID ${step.elementID}`, 'OK', { duration: 5000 });
          // No actual step has been set. This probably means the end of the tour or an error.
          // In any case, the tour is done.
          this.endTour();
        }
      }
      if (abort) return false;
    }
    return false;
  }

  activateCurrentStep() {
    const flow = this.activeFlow$.getValue();
    if ('flowSequence' in flow === false || flow.flowSequence.length === 0) return;
    const active = this.activeStep$.getValue();
    const current = flow.flowSequence[this.currentStepIndex];

    // If there are no more steps, end the tour
    if (!current) return this.endTour();

    if (active?.elementID !== current?.elementID) {
      // Only set if not currently active
      this.activeStep$.next(current);
    } else {
      setTimeout(() => {
        if (!this.activeStep$.getValue()?._active) this.playActiveStep();
      });
    }
  }

  /**
   * Get next step in the flow and activate it.
   */
  nextStep() {
    if (this.tourState$.getValue() === TourState.INACTIVE) return;

    const currentSequence = this.activeFlow$.getValue().flowSequence;

    // Reset current step
    const currentStep = currentSequence[this.currentStepIndex];
    if (currentStep?.repeatUntilElementID != null && this.findItem(currentStep.repeatUntilElementID) == null) {
      // Repeat step until element is visible
      this.playActiveStep(currentStep);
    } else {
      // Activate next step
      this.increaseNextStepIndex(1);
      this.activateCurrentStep();
    }
  }

  increaseNextStepIndex(increase = -1) {
    if (this.tourState$.getValue() === TourState.INACTIVE) return;

    const currentSequence = this.activeFlow$.getValue().flowSequence;

    // Reset current step
    const currentStep = currentSequence[this.currentStepIndex];
    if (currentStep) this.findItem(currentStep.elementID)?.deactivate(false);

    // Reactivate previous step
    this.currentStepIndex =
      increase > 0
        ? this.currentStepIndex + increase > 0
          ? this.currentStepIndex + increase
          : this.currentStepIndex
        : currentSequence.length - 1 + increase >= this.currentStepIndex
          ? this.currentStepIndex + increase
          : this.currentStepIndex;
  }

  async markAsPlayed(flow: OnboardingConfig) {
    // Post "user-completed" state of a given flowID to the backend
    let payload: Record<string, any> = {};
    if (flow.flowType === 'collect') {
      payload = flow.flowSequence.reduce((acc, step) => {
        return Object.assign({}, acc, step.collectedValue);
      }, {});
    }
    flow.flowPlayedMs = new Date().getTime();
    return await firstValueFrom(
      this.http
        .put(`/api/flex/Onboarding/${flow.flowID}`, payload)
        .pipe(tap(() => this.cache.invalidate('/api/flex/Onboarding'))),
    );
  }

  async markAsActive(flowID: number) {
    // Post re-activation of a given flowID to the backend
    const flow = await firstValueFrom(this.http.get<OnboardingConfig>(`/api/flex/Onboarding/${flowID}`));
    this.activeFlow$.next(flow);
    this.play();
  }

  getAllFlows(): Observable<OnboardingList[]> {
    // Return all flows from the backend
    return this.http.get<OnboardingList[]>(`/api/flex/Onboarding`);
  }

  getActiveFlow() {
    // Read the currently active flow from backend
    return this.http.get<OnboardingConfig>(`/api/flex/Onboarding/ActiveFlow`).pipe(
      // This should never fail. If it does, the app must ignore and mozy on down the road.
      catchError(() => of(null)),
      tap(flow => flow && this.activeFlow$.next(flow)),
    );
  }
}
