export interface OnboardingList {
  flowID: number;
  // I.e. 'first-booking' - takes the user on a tour of how to book a workspace
  flowName: string;
  sortOrder: number;
  flowType: 'collect' | 'view';
  flowPlayedMs: null | number;
  flowIfFeature: string[];
}
export interface OnboardingConfig extends OnboardingList {
  // The items to be shown on the tour, in order
  flowSequence: OnboardingStep[];
  _isPlaying?: boolean;
}

export type WaitForType = 'user-input' | 'click' | 'time' | 'next';

export enum WaitFor {
  USER_INPUT = 'user-input',
  CLICK = 'click',
  TIME = 'time',
  NEXT = 'next',
}

export interface OnboardingStep {
  // Any unique name for this item. This is the language-key used to display info to the user.
  name: string;
  // The hardcoded onboarding-id in the frontend element (e.g. 'onboarding-datepicker').
  // For elements in a list, this can also contain the nth-item, like "element:0" (the first item with the "element" id)
  elementID: string;
  // If set, this item will cause navigation when activated. Can contain route-params like ':id'
  route?: string;
  // How to proceed to next item
  waitFor: WaitForType;
  // If `waitFor: 'time'`, how long to wait in ms before proceeding to next item
  timeout?: number;
  // Repeat this step until a given elementID is present in the DOM
  repeatUntilElementID?: string;
  // Used in frontend to hold the position of the active item in the tour
  _active?: boolean;
  // The value collected from the user if this step is part of a 'collect' flow
  collectedValue?: Record<string, any>;
}
