<div class="box" [ngStyle]="elStyle"></div>

<article #hint [ngStyle]="hintStyle">
  <header>
    <button mat-icon-button (click)="endTour()" [title]="'End tour' | translate">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div [innerHTML]="content"></div>
  <footer *ngIf="stepConfig?.waitFor === 'next'">
    <div></div>
    <button mat-raised-button (click)="next()">Next</button>
  </footer>
</article>
