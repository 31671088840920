import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@logic-suite/shared/auth/auth.service';
import { RequestCache } from '@logic-suite/shared/cache';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { errorToString, retryOn504 } from '@logic-suite/shared/utils';
import { catchError, filter, firstValueFrom, map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { EmployeeService } from './employee.service';
import { Employee, EmployeeCar } from './profile.model';
import { AuthState } from '@logic-suite/shared/auth/auth-state.enum';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private http = inject(HttpClient);
  private cache = inject(RequestCache);
  private auth = inject(AuthService);
  private employee = inject(EmployeeService);
  private storage = inject(ApplicationStorageService);
  private snack = inject(MatSnackBar);
  private router = inject(Router);

  carsSubject = new ReplaySubject<EmployeeCar[]>(1);
  cars$ = this.carsSubject.pipe(filter((c) => !!c));

  constructor() {
    this.init();
  }

  init() {
    firstValueFrom(
      this.auth.isLoggedIn$.pipe(
        filter((flag) => flag === true),
        take(1),
        switchMap(() => this.getEmployee()),
      ),
    )
      .then((employee) => this.employee.setEmployee(employee))
      .catch((err) => {
        // If we cannot load employee, we have to die.
        this.snack.open(errorToString(err), 'OK', { duration: 5000 });
        if ([500, 503].includes(err.status)) {
          this.router.navigate(['/500']);
        }
        return true;
      });
  }

  private getEmployee() {
    return this.http.get<Employee>(`/api/flex/Employee`).pipe(
      retryOn504(),
      map((emp: Employee) => {
        // Backward compatibility with api
        emp.customers = emp.customers.map((c) => {
          if (!c.logoUrl && !!c.customerLogoUrl) {
            c.logoUrl = {
              light: c.customerLogoUrl,
              dark: c.customerLogoUrl,
            };
          }
          return c;
        });
        if (emp.zones?.length > 0 && !emp.zoneIDs) {
          // Backward compatibility with map component
          emp.zoneIDs = emp.zones.map((z) => z.zoneID);
        }
        return emp;
      }),
    );
  }

  uploadImage(image: string): Observable<any> {
    const blob = this.dataURItoBlob(image);
    const fd = new FormData();
    fd.append('file', blob);
    // return this.http.post('/api/flex/Employee/Image', image).pipe(
    return this.http.post(`/api/flex/Employee/Image`, fd, { responseType: 'text' }).pipe(
      retryOn504(),
      catchError((err) => {
        console.error(err);
        return err;
      }),
      tap((res: any) => {
        this.cache.invalidate('/api/flex/Employee');
        this.cache.invalidate('/api/flex/Team');
      }),
    );
  }

  removeImage() {
    return this.http.delete(`/api/flex/Employee/Image`).pipe(tap(() => this.cache.invalidate('/api/flex/Employee')));
  }

  private dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const dw = new DataView(ab);

    for (let i = 0; i < byteString.length; i++) {
      dw.setUint8(i, byteString.charCodeAt(i));
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString });
  }

  updateVisibility(val: boolean): Observable<boolean> {
    return this.http.put<boolean>(`/api/flex/Employee/Invisible/${val === true}`, null).pipe(
      tap(() => {
        this.cache.invalidate('/api/flex/Employee');
        this.cache.invalidate('/api/flex/Team');
        firstValueFrom(this.employee.getEmployee()).then((emp) => {
          emp.invisible = val;
          this.employee.setEmployee(emp);
        });
      }),
    );
  }

  private setCars(cars: EmployeeCar[]) {
    this.storage.setItem('cars', cars);
    this.carsSubject.next(cars);
  }

  getCars() {
    // Retrieve cars from local storage, refresh from network
    if (this.storage.hasItem('cars')) {
      this.carsSubject.next(this.storage.getItem('cars'));
      return this.cars$;
    }
    firstValueFrom(this.http.get<EmployeeCar[]>(`/api/flex/EmployeeParking/Car`).pipe(retryOn504())).then((cars) =>
      this.setCars(cars),
    );
    return this.cars$;
  }

  saveCars(cars: EmployeeCar[]) {
    return this.http.post<EmployeeCar[]>(`/api/flex/EmployeeParking/Car`, cars).pipe(
      tap(() => {
        this.storage.removeItem('cars'); // Force reload
        this.carsSubject.next(undefined as unknown as EmployeeCar[]);
        this.cache.invalidate(`/api/flex/EmployeeParking/Car`);
      }),
    );
  }

  removeCars(cars: EmployeeCar[]) {
    return this.http
      .delete<boolean>(`/api/flex/EmployeeParking/Car`, { params: { employeeCarID: cars.map((c) => c.employeeCarID) } })
      .pipe(
        tap(() => {
          this.storage.removeItem('cars'); // Force reload
          this.carsSubject.next(undefined as unknown as EmployeeCar[]);
          this.cache.invalidate(`/api/flex/EmployeeParking/Car`);
        }),
      );
  }

  subscribeToPushNotifications(sub: PushSubscription) {
    return this.auth.authState$.pipe(
      filter((authState) => authState === AuthState.AUTHENTICATED),
      switchMap(() => this.http.post(`/api/flex/PushNotification`, sub)),
    );
  }
}
