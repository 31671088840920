import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { OnboardingItemDirective } from './onboarding-item.directive';
import { OnboardingItemComponent } from './onboarding-item/onboarding-item.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule,
    // Onboarding translations uses it's own catalog in our CMS. We need to load it separately.
    TranslateModule,
  ],
  declarations: [OnboardingItemComponent, OnboardingItemDirective],
  exports: [OnboardingItemDirective],
})
export class OnboardingModule {}
