import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { toHTML } from '@portabletext/to-html';
import { BehaviorSubject, Subscription, combineLatest, firstValueFrom } from 'rxjs';

import { DomSanitizer } from '@angular/platform-browser';
import { scrollIntoView } from '@logic-suite/shared/utils';
import { OnboardingStep } from '../onboarding.model';
import { OnboardingService } from '../onboarding.service';

// Listen for all DOM changes once globally, and provide a subject each instance can subscribe to.
const domModified$ = new BehaviorSubject<MutationRecord[]>([]);
const mutationObs = new MutationObserver(mutationList => domModified$.next(mutationList));
mutationObs.observe(document.body, { attributes: true, childList: true, subtree: true });

@Component({
  selector: 'lib-onboarding-item',
  templateUrl: './onboarding-item.component.html',
  styleUrls: ['./onboarding-item.component.scss'],
})
export class OnboardingItemComponent implements OnInit, AfterViewInit, OnDestroy {
  private onboarder = inject(OnboardingService);
  private zone = inject(NgZone);
  private translate = inject(TranslateService);
  private dom = inject(DomSanitizer);
  private cdr = inject(ChangeDetectorRef);

  _stepConfig?: OnboardingStep;
  get stepConfig() {
    return this._stepConfig as OnboardingStep;
  }
  set stepConfig(step: OnboardingStep) {
    this._stepConfig = step;
    this.content = this.getStepContent(step);
  }
  highlightElement?: ElementRef<any>;

  @HostBinding('style') hostStyle: { [key: string]: string } = {};
  elStyle: { [key: string]: string } = {};
  hintStyle: { [key: string]: string } = {};

  @HostBinding('class')
  hintPosition: 'top' | 'bottom' = 'top';

  @ViewChild('hint') hintElement!: ElementRef<any>;

  private resizeObs = new ResizeObserver(() => this.calculateStyle());
  subscriptions: Subscription[] = [];

  content?: SafeHtml;
  watching = new Set<HTMLElement>();

  ngOnInit(): void {
    if (this.highlightElement) {
      this.resizeObs.observe(this.highlightElement.nativeElement);
      this.subscriptions.push(combineLatest([domModified$, this.zone.onStable]).subscribe(() => this.calculateStyle()));
      (Array.from(document.querySelectorAll("[class*='ng-tns-']")) as HTMLElement[]).forEach((el: HTMLElement) => {
        this.watching.add(el);
        el.addEventListener('scroll', this.calculateStyle.bind(this));
      });
    }
    firstValueFrom(this.translate.get('test'));
  }

  ngAfterViewInit(): void {
    !!this.highlightElement && scrollIntoView(this.highlightElement.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObs.unobserve(this.highlightElement?.nativeElement);
    this.watching.forEach(el => el.removeEventListener('scroll', this.calculateStyle.bind(this)));
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  next() {
    this.onboarder.nextStep();
  }

  endTour() {
    this.onboarder.endTour();
    this.stepConfig._active = false;
  }

  @HostListener('window:resize')
  calculateStyle() {
    // setTimeout(() => {
    const arrowHeight = 15;
    const rect = this.highlightElement?.nativeElement.getBoundingClientRect();
    // Sets style for the host element
    this.hostStyle = {
      left: rect?.left + 'px',
      top: rect?.top + 'px',
      width: rect?.width + 'px',
    };
    // Sets style for the box element
    const radius = Number(getComputedStyle(this.highlightElement?.nativeElement).borderRadius);
    this.elStyle = {
      height: rect?.height + 'px',
      ...(radius > 0 ? { borderRadius: `${radius}` } : {}),
    };
    // Sets style for the hint tooltip element
    this.hintStyle = {
      ...(this.hintPosition == 'top' ? { top: rect?.height + arrowHeight + 'px' } : {}),
      ...(this.hintPosition == 'bottom' ? { bottom: rect?.height + arrowHeight * 2 + 'px' } : {}),
    };
    if (this.hintElement) {
      const bounding = this.hintElement.nativeElement.getBoundingClientRect();
      if (
        bounding.top < 0 ||
        rect?.top + rect?.height + bounding?.height + arrowHeight <
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        // Hint should be displayed below the element with arrow pointing at top
        this.hintPosition = 'top';
      } else if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
        // Hint should be displayed above the element with arrow pointing at bottom
        this.hintPosition = 'bottom';
      }
      // if (bounding.left < 0) // Left side is out of viewport'
      // Right side is out of viewport
      // if (bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
      this.cdr.markForCheck();
    }
    // });
  }

  getStepContent(stepConfig: OnboardingStep): SafeHtml | undefined {
    if (stepConfig.name) {
      const portableText = this.translate.instant(stepConfig.name);
      if (typeof portableText === 'string') {
        return this.dom.bypassSecurityTrustHtml(portableText);
      }
      const html = toHTML(portableText);
      return this.dom.bypassSecurityTrustHtml(html);
    }
    return undefined;
  }
}
